export class CacheRequestsController {
  cacheRequestsController = {};

  cacheRequest = ({ identifier, ttl }) => {
    if (this.cacheRequestsController[identifier]) {
      const lastRequestTime = this.cacheRequestsController[identifier];
      const now = new Date().getTime();

      if (now - lastRequestTime < ttl) {
        const timeRemaining = ttl - (now - lastRequestTime);
        return timeRemaining;
      }
    }
    this.cacheRequestsController[identifier] = new Date().getTime();
    return null;
  };

  cacheHasExpired = ({ identifier, ttl }) => {
    if (this.cacheRequestsController[identifier]) {
      const lastRequestTime = this.cacheRequestsController[identifier];
      const now = new Date().getTime();
      return now - lastRequestTime > ttl;
    }
    return true;
  };
}
