/* eslint-disable import/no-mutable-exports */
import BTC_ICON from './assets/currency-icons/BTC.svg';
import ETH_ICON from './assets/currency-icons/ETH.svg';
import USDT_ICON from './assets/currency-icons/USDT.svg';
import BNB_ICON from './assets/currency-icons/BNB.svg';
import CRZO_ICON from './assets/currency-icons/CRZO.svg';
import PAXG_ICON from './assets/currency-icons/PAXG.svg';
import SLP_ICON from './assets/currency-icons/SLP.svg';
import AXS_ICON from './assets/currency-icons/AXS.svg';
import CAKE_ICON from './assets/currency-icons/CAKE.svg';
import SOL_ICON from './assets/currency-icons/SOL.svg';
import UNI_ICON from './assets/currency-icons/UNI.svg';
import USDC_ICON from './assets/currency-icons/USDC.svg';
import POLIS_ICON from './assets/currency-icons/POLIS.svg';
import ATLAS_ICON from './assets/currency-icons/ATLAS.svg';
import ADA_ICON from './assets/currency-icons/ADA.svg';
import LUNA_ICON from './assets/currency-icons/LUNA.svg';
import LUNC_ICON from './assets/currency-icons/LUNC.svg';
import GMT_ICON from './assets/currency-icons/GMT.svg';
import ABFV_ICON from './assets/currency-icons/ABFY.png';
import BUSD_ICON from './assets/currency-icons/BUSD.svg';
import XRP_ICON from './assets/currency-icons/XRP.svg';
import MATIC_ICON from './assets/currency-icons/MATIC.svg';
import DOT_ICON from './assets/currency-icons/DOT.svg';
import LINK_ICON from './assets/currency-icons/LINK.svg';
import { FirebaseService } from './services/firebaseServices';
import { CacheRequestsController } from './controllers/CacheRequests';

let IS_RFQ_ACTIVE;

let FIREBASE_FEATURE_FLAGS = {};

let DEPOSIT_BANK_INFO = {};

let FIREBASE_CURRENCIES_ISSUES = {};

let FIREBASE_MGM_INFO = {};

let FIREBASE_LINKS = {
  learnMoreMgm: '',
  learnMoreBityCardBenefits: '',
  urlBitybankPEPTerms: '',
  urlBitybankUseTerms: '',
  infoDepositBixUrl: '',
};

let FIREBASE_TRANSLATIONS = {
  cashbackMarketingEvent: {},
};

const isRFQDisabled = async () => {
  const rfqIsDisabled = FirebaseService.isRFQDisabled();
  IS_RFQ_ACTIVE = !rfqIsDisabled;
};

const getDepositBankInfo = () => {
  const depositBankInfo = FirebaseService.depositBankInfo();
  DEPOSIT_BANK_INFO = depositBankInfo;
  DEPOSIT_BANK_INFO.address = `${depositBankInfo.bankCode}|${depositBankInfo.branch}|${depositBankInfo.account}`;
};

const getCurrenciesIssues = () => {
  const firebaseCurrenciesIssues = FirebaseService.currencyIssues();
  FIREBASE_CURRENCIES_ISSUES = {
    firebaseCurrenciesIssues,
  };
};

const triggerTradeEvent = async (nameEvent, parametersEvent) => {
  try {
    FirebaseService.tradeAnalyticsEvents(nameEvent, parametersEvent);
    console.log('Evento de trade registrado no Firebase Analytics');
  } catch (error) {
    console.error('Erro ao registrar evento de trade: ', error);
  }
};

const getFirebaseMgmInfo = () => {
  const mgmInfo = FirebaseService.mgmInfo();
  FIREBASE_MGM_INFO = mgmInfo;
}

const getFirebaseLinks = () => {
  const learnMoreMgm = FirebaseService.learnMoreMgm();
  const learnMoreBityCardBenefits = FirebaseService.learnMoreBityCardBenefits();
  const urlBitybankPEPTerms = FirebaseService.urlBitybankPEPTerms();
  const urlBitybankUseTerms = FirebaseService.urlBitybankUseTerms();
  const infoDepositBixUrl = FirebaseService.infoDepositBixUrl();

  FIREBASE_LINKS = {
    learnMoreMgm,
    learnMoreBityCardBenefits,
    urlBitybankPEPTerms,
    urlBitybankUseTerms,
    infoDepositBixUrl,
  };
};

const getFirebaseTranslations = () => {
  const cashbackMarketingEvent = FirebaseService.cashbackMarketingEvent();
  FIREBASE_TRANSLATIONS = {
    cashbackMarketingEvent,
  };
};

const getFirebaseFeatureFlags = () => {
  const disableThrottler = FirebaseService.disableThrottler();
  const showCryptoConverter = FirebaseService.showCryptoConverter();
  FIREBASE_FEATURE_FLAGS = {
    disableThrottler,
    showCryptoConverter,
  };
};

const fetchAndActivate = async () => {
  await FirebaseService.fetchAndActiveRemoteConfig();
  isRFQDisabled();
  getDepositBankInfo();
  getFirebaseLinks();
  getFirebaseTranslations();
  getFirebaseFeatureFlags();
  getCurrenciesIssues();
  getFirebaseMgmInfo();
};

fetchAndActivate();

const DEV = process.env.REACT_APP_ENV !== 'production';

console.log('ENV:', process.env.REACT_APP_ENV);

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const API_URL = process.env.REACT_APP_API_URL;
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_ID;
// const API_URL = 'https://api.bitpreco.com/api.php';

const VERSION_WEB = process.env.REACT_APP_VERSION_WEB;
const CNPJ_BITYPRECO = '29738313000123';
const DEFAULT_SYNC_INTERVAL = DEV ? 60000 : 30000;
const SHORT_SYNC_INTERVAL = DEV ? 12000 : 5000;
const ORDERBOOK_SYNC_INTERVAL = DEV ? 60000 : 6000;
const LONG_SYNC_INTERVAL = DEV ? 120000 : 60000;

const MS_TEN_MINUTES = 600000;
const MS_KEEP_ALIVE_INTERVAL_CHECK = 30000;

const LOCAL_EXCHANGE_NAME = 'BityPreço';

const EXCHANGE_ICONS_ENDPOINT = 'https://bitypreco.com/assets/img/exchange/';
const BLOG_TRANSFER_SECURITY_POST = 'https://blog.bitpreco.com/cuidados-ao-transferir-suas-criptomoedas/';
const APP_STORE_ADDRESS = 'https://apps.apple.com/br/app/biscoint-lite/id1588152503';
const GOOGLE_PLAY_ADDRESS = 'https://play.google.com/store/apps/details?id=io.biscoint.lite';
const VARIANT = process.env.REACT_APP_CODENAME;
const SUPPORT_MAIL = 'suporte@bitpreco.com';
const SUPPORT_ADDRESS = 'https://suporte.bity.com.br/pt-BR/';

const GOOGLE_RECAPTCHA_SITE_KEY = '6LeO32IUAAAAAHQ_a3WhLK-b-ZthWrf5tFSfHaxe';

const GOOGLE_RECAPTCHA_SITE_KEY_V3 = '6LehruYUAAAAAE_i5aFAofOAW9BwqwsVrHP9ld5y';

const BTC_BLOCK_EXPLORER = DEV ? 'https://testnet.blockchain.info/tx/' : 'https://explorer.btc.com/btc/transaction/';
const ETH_BLOCK_EXPLORER = DEV ? 'https://sepolia.etherscan.io/tx/' : 'https://etherscan.io/tx/';
const BSC_BLOCK_EXPLORER = DEV ? 'https://testnet.bscscan.com/tx/' : 'https://bscscan.com/tx/';
const ADA_BLOCK_EXPLORER = DEV ? 'https://testnet.cardanoscan.io/transaction/' : 'https://cardanoscan.io/transaction/';
const LUNA_BLOCK_EXPLORER = DEV ? 'https://finder.terra.money/testnet/tx/' : 'https://finder.terra.money/mainnet/tx/';
const LUNC_BLOCK_EXPLORER = DEV ? 'https://finder.terra.money/testnet/tx/' : 'https://finder.terra.money/mainnet/tx/';
const TRON_BLOCK_EXPLORER = DEV ? 'https://shasta.tronscan.org/#/transaction/' : 'https://tronscan.org/#/transaction/';

const getSolBlockExplorer = address => (DEV ? `https://solscan.io/tx/${address}?cluster=testnet` : `https://solscan.io/tx/${address}`);
const getEthBlockExplorer = address => `${ETH_BLOCK_EXPLORER}${address}`;
const getBtcBlockExplorer = address => `${BTC_BLOCK_EXPLORER}${address}`;
const getBscBlockExplorer = address => `${BSC_BLOCK_EXPLORER}${address}`;
const getAdaBlockExplorer = address => `${ADA_BLOCK_EXPLORER}${address}`;
const getLunaBlockExplorer = address => `${LUNA_BLOCK_EXPLORER}${address}`;
const getLuncBlockExplorer = address => `${LUNC_BLOCK_EXPLORER}${address}`;
const getTronBlockExplorer = address => `${TRON_BLOCK_EXPLORER}${address}`;

const DEMO_ACCOUNT_EMAIL = 'open@bitpreco.com';
const DEMO_ACCOUNT_PASS = 'Open$1234';

const BTC_SATOSHI_SCALE = 8;

const TIMEOUT_COPY_WALLET_ADDRESS_CHECK = 2000;
const AVAILABLE_OPERATIONS = ['ALLOPERATIONS', 'WITHDRAWAL', 'SELL', 'BUY', 'DEPOSIT', 'CARD'];

const AVAILABLE_TOKENS = [
  {
    public: true,
    type: 'marketplace',
    name: 'Bitcoin',
    tickerSymbol: 'BTC',
    label: 'Bitcoin',
    market: 'BTC-BRL',
    tradingViewChartSymbol: 'BTCBRL',
    tradingViewChartSymbolPROD: 'BTC_BRL',
    icon: BTC_ICON,
    baseColor: '#f49504',
    minimumDeposit: 0.0001,
    order_book: {
      view: true,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  }, {
    public: true,
    type: 'marketplace',
    name: 'Ether',
    tickerSymbol: 'ETH',
    label: 'Ether',
    market: 'ETH-BRL',
    tradingViewChartSymbol: 'ETHBRL',
    tradingViewChartSymbolPROD: 'ETH_BRL',
    icon: ETH_ICON,
    baseColor: '#6b76b1',
    minimumDeposit: 0.025,
    order_book: {
      view: true,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  }, {
    public: true,
    type: 'marketplace',
    name: 'USDT',
    tickerSymbol: 'USDT',
    label: 'Dólar Tether',
    market: 'USDT-BRL',
    tradingViewChartSymbol: 'USDTBRL',
    tradingViewChartSymbolPROD: 'USDT_BRL',
    icon: USDT_ICON,
    baseColor: '#4a9c85',
    minimumDeposit: 10,
    order_book: {
      view: true,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'LUNA',
    tickerSymbol: 'LUNA',
    label: 'Terra',
    market: 'LUNA-BRL',
    tradingViewChartSymbol: 'LUNABRL',
    tradingViewChartSymbolPROD: 'LUNA_BRL',
    icon: LUNA_ICON,
    baseColor: '#172852',
    minimumDeposit: 0.01,
    order_book: {
      view: false,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: false,
    },
    withdrawal: {
      view: false,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'LUNC',
    tickerSymbol: 'LUNC',
    label: 'Terra Classic',
    market: 'LUNC-BRL',
    tradingViewChartSymbol: 'LUNCBRL',
    tradingViewChartSymbolPROD: 'LUNC_BRL',
    icon: LUNC_ICON,
    baseColor: '#172852',
    minimumDeposit: 0.01,
    order_book: {
      view: false,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: false,
    },
    withdrawal: {
      view: false,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'ADA',
    tickerSymbol: 'ADA',
    label: 'Cardano',
    market: 'ADA-BRL',
    tradingViewChartSymbol: 'ADABRL',
    tradingViewChartSymbolPROD: 'ADA_BRL',
    icon: ADA_ICON,
    baseColor: '#2565bb',
    minimumDeposit: 1,
    order_book: {
      view: true,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'BNB',
    tickerSymbol: 'BNB',
    label: 'Binance Coin',
    market: 'BNB-BRL',
    tradingViewChartSymbol: 'BNBBRL',
    tradingViewChartSymbolPROD: 'BNB_BRL',
    icon: BNB_ICON,
    baseColor: '#e6b02b',
    minimumDeposit: 0.01,
    order_book: {
      view: true,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'GMT',
    tickerSymbol: 'GMT',
    label: 'Stepn',
    market: 'GMT-BRL',
    tradingViewChartSymbol: 'GMTBRL',
    tradingViewChartSymbolPROD: 'GMT_BRL',
    icon: GMT_ICON,
    baseColor: '#E8CA7D',
    minimumDeposit: 1,
    order_book: {
      view: false,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'Solana',
    tickerSymbol: 'SOL',
    label: 'Solana',
    market: 'SOL-BRL',
    tradingViewChartSymbol: 'SOLBRL',
    tradingViewChartSymbolPROD: 'SOL_BRL',
    icon: SOL_ICON,
    baseColor: '#069f9f',
    minimumDeposit: 0.001,
    order_book: {
      view: true,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'UNI',
    tickerSymbol: 'UNI',
    label: 'UniSwap',
    market: 'UNI-BRL',
    tradingViewChartSymbol: 'UNIBRL',
    tradingViewChartSymbolPROD: 'UNI_BRL',
    icon: UNI_ICON,
    baseColor: '#d5136f',
    minimumDeposit: 1,
    order_book: {
      view: false,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'USDC',
    tickerSymbol: 'USDC',
    label: 'Dólar Coin',
    market: 'USDC-BRL',
    tradingViewChartSymbol: 'USDCBRL',
    tradingViewChartSymbolPROD: 'USDC_BRL',
    icon: USDC_ICON,
    baseColor: '#2361a7',
    minimumDeposit: 10,
    order_book: {
      view: true,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  {
    public: false,
    type: 'exchange',
    name: 'USDBP',
    tickerSymbol: 'USDBP',
    label: 'USD Bitypreço',
    market: 'USDBP-BRL',
    tradingViewChartSymbol: 'USDBPBRL',
    tradingViewChartSymbolPROD: 'USDBP_BRL',
    baseColor: '#2361a7',
    minimumDeposit: 10,
    order_book: {
      view: true,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'Polis',
    tickerSymbol: 'POLIS',
    label: 'Star Atlas DAO',
    market: 'POLIS-BRL',
    tradingViewChartSymbol: 'POLISBRL',
    tradingViewChartSymbolPROD: 'POLIS_BRL',
    icon: POLIS_ICON,
    baseColor: '#47568c',
    minimumDeposit: 1,
    order_book: {
      view: true,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'ATLAS',
    tickerSymbol: 'ATLAS',
    label: 'Star Atlas',
    market: 'ATLAS-BRL',
    tradingViewChartSymbol: 'ATLASBRL',
    tradingViewChartSymbolPROD: 'ATLAS_BRL',
    icon: ATLAS_ICON,
    baseColor: '#5bbdbd',
    minimumDeposit: 50,
    order_book: {
      view: true,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'AXS',
    tickerSymbol: 'AXS',
    label: 'Axie Infinity',
    market: 'AXS-BRL',
    tradingViewChartSymbol: 'AXSBRL',
    tradingViewChartSymbolPROD: 'AXS_BRL',
    icon: AXS_ICON,
    baseColor: '#11b8d5',
    minimumDeposit: 0.5,
    order_book: {
      view: false,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'CAKE',
    tickerSymbol: 'CAKE',
    label: 'PancakeSwap',
    market: 'CAKE-BRL',
    tradingViewChartSymbol: 'CAKEBRL',
    tradingViewChartSymbolPROD: 'CAKE_BRL',
    icon: CAKE_ICON,
    baseColor: '#bb743b',
    minimumDeposit: 1,
    order_book: {
      view: false,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'PAXG',
    tickerSymbol: 'PAXG',
    label: 'Ouro PAX',
    market: 'PAXG-BRL',
    tradingViewChartSymbol: 'PAXGBRL',
    tradingViewChartSymbolPROD: 'PAXG_BRL',
    icon: PAXG_ICON,
    baseColor: '#b9971f',
    minimumDeposit: 0.01,
    order_book: {
      view: false,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'SLP',
    tickerSymbol: 'SLP',
    label: 'Smooth love potion',
    market: 'SLP-BRL',
    tradingViewChartSymbol: 'SLPBRL',
    tradingViewChartSymbolPROD: 'SLP_BRL',
    icon: SLP_ICON,
    baseColor: '#FF93A2',
    minimumDeposit: 100,
    order_book: {
      view: false,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'Cruzeiro Token',
    tickerSymbol: 'CRZO',
    label: 'Cruzeiro Token',
    market: 'CRZO-BRL',
    tradingViewChartSymbol: 'CRZOBRL',
    tradingViewChartSymbolPROD: 'CRZO_BRL',
    icon: CRZO_ICON,
    baseColor: '#076cc3',
    minimumDeposit: 0,
    order_book: {
      view: true,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'Ambify',
    tickerSymbol: 'ABFY',
    label: 'Ambify',
    market: 'ABFY-BRL',
    tradingViewChartSymbol: 'ABFYBRL',
    tradingViewChartSymbolPROD: 'ABFY_BRL',
    icon: ABFV_ICON,
    baseColor: '#afec03',
    minimumDeposit: 10,
    order_book: {
      view: true,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: true,
    },
    withdrawal: {
      view: true,
    },
  },
  // Only RFQ
  {
    public: true,
    type: 'exchange',
    name: 'BUSD',
    tickerSymbol: 'BUSD',
    label: 'Binance USD',
    market: 'BUSD-BRL',
    tradingViewChartSymbol: 'BUSDBRL',
    tradingViewChartSymbolPROD: 'BUSD_BRL',
    icon: BUSD_ICON,
    baseColor: '#F0B90B',
    minimumDeposit: 1,
    order_book: {
      view: false,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: false,
    },
    withdrawal: {
      view: false,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'XRP',
    tickerSymbol: 'XRP',
    label: 'XRP',
    market: 'XRP-BRL',
    tradingViewChartSymbol: 'XRPBRL',
    tradingViewChartSymbolPROD: 'XRP_BRL',
    icon: XRP_ICON,
    baseColor: '#23292f',
    minimumDeposit: 0.01,
    order_book: {
      view: false,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: false,
    },
    withdrawal: {
      view: false,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'DOT',
    tickerSymbol: 'DOT',
    label: 'Polkadot',
    market: 'DOT-BRL',
    tradingViewChartSymbol: 'DOTBRL',
    tradingViewChartSymbolPROD: 'DOT_BRL',
    icon: DOT_ICON,
    baseColor: '#E6007A',
    minimumDeposit: 0.01,
    order_book: {
      view: false,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: false,
    },
    withdrawal: {
      view: false,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'LINK',
    tickerSymbol: 'LINK',
    label: 'Chainlink',
    market: 'LINK-BRL',
    tradingViewChartSymbol: 'LINKBRL',
    tradingViewChartSymbolPROD: 'LINK_BRL',
    icon: LINK_ICON,
    baseColor: '#2a5ada',
    minimumDeposit: 0.01,
    order_book: {
      view: false,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: false,
    },
    withdrawal: {
      view: false,
    },
  },
  {
    public: true,
    type: 'exchange',
    name: 'MATIC',
    tickerSymbol: 'MATIC',
    label: 'Polygon',
    market: 'MATIC-BRL',
    tradingViewChartSymbol: 'MATICBRL',
    tradingViewChartSymbolPROD: 'MATIC_BRL',
    icon: MATIC_ICON,
    baseColor: '#8247E5',
    minimumDeposit: 0.01,
    order_book: {
      view: true,
    },
    rfq: {
      view: true,
    },
    deposit: {
      view: false,
    },
    withdrawal: {
      view: false,
    },
  },
];

const AVAILABLE_NETWORK = {
  BTC: 'BTC (Original)',
  BSC: 'BEP20 (BSC)',
  ETH: 'ERC20 (Ethereum)',
  SOL: 'SOL (Solana)',
  ADA: 'ADA (Cardano)',
  TERRAC: 'Terra Classic (lunc)',
  TERRA: 'Terra (luna)',
  LN: 'Lightning Network',
};

const USDT = 'USDT';
const ETH = 'ETH';
const BTC = 'BTC';
const BUSD = 'BUSD';
const BRL = 'BRL';
const BSC = 'BSC';
const BNB = 'BNB';
const PAXG = 'PAXG';
const CRZO = 'CRZO';
const AXS = 'AXS';
const SLP = 'SLP';
const CAKE = 'CAKE';
const UNI = 'UNI';
const SOL = 'SOL';
const POLIS = 'POLIS';
const ATLAS = 'ATLAS';
const USDC = 'USDC';
const USDBP = 'USDBP';
const ABFY = 'ABFY';
const ADA = 'ADA';
const LUNA = 'LUNA';
const LUNC = 'LUNC';
const GMT = 'GMT';
const TERRA = 'TERRA';
const TERRAC = 'TERRAC';
const DOT = 'DOT';
const MATIC = 'MATIC';
const LINK = 'LINK';
const XRP = 'XRP';

const INTERNAL = 'internal';

const CURRENCY_ICONS_URL = 'https://bitypreco.com/assets/img/coins/logos/bitpreco-moeda';

// chart constants
const DATA_FEED_URL = process.env.REACT_APP_TRADEVIEW_URL; // do not put a forward slash "/" here
const CHARTS_STORAGE_URL = process.env.REACT_APP_CHARTS_STORAGE_URL;
const SHOW_INTERNAL_TRADEVIEW = true;
const JIVO_WIDGET_ID = process.env.REACT_APP_JIVO_WIDGET_ID;

const product = 'BITYBANK';



// Errors translates URL
const TRANSLATION_ERRORS_URL = 'https://static.bitypreco.com/config/errors.json';

const dataRequestCacheController = new CacheRequestsController();

const config = {
  AVAILABLE_NETWORK,
  DEMO_ACCOUNT_EMAIL,
  DEMO_ACCOUNT_PASS,
  DEV,
  DEFAULT_SYNC_INTERVAL,
  API_URL,
  SOCKET_URL,
  VARIANT,
  SUPPORT_MAIL,
  LONG_SYNC_INTERVAL,
  SHORT_SYNC_INTERVAL,
  GOOGLE_RECAPTCHA_SITE_KEY,
  GOOGLE_RECAPTCHA_SITE_KEY_V3,
  SHOW_INTERNAL_TRADEVIEW,
  ETH_BLOCK_EXPLORER,
  BSC_BLOCK_EXPLORER,
  BTC_BLOCK_EXPLORER,
  getSolBlockExplorer,
  getEthBlockExplorer,
  getBtcBlockExplorer,
  getBscBlockExplorer,
  getAdaBlockExplorer,
  getLunaBlockExplorer,
  getLuncBlockExplorer,
  getTronBlockExplorer,
  BTC_SATOSHI_SCALE,
  AVAILABLE_TOKENS,
  AVAILABLE_OPERATIONS,
  DATA_FEED_URL,
  CHARTS_STORAGE_URL,
  EXCHANGE_ICONS_ENDPOINT,
  LOCAL_EXCHANGE_NAME,
  product,
  MS_TEN_MINUTES,
  MS_KEEP_ALIVE_INTERVAL_CHECK,
  USDT,
  ETH,
  BRL,
  BTC,
  BUSD,
  BSC,
  BNB,
  CRZO,
  AXS,
  SLP,
  CAKE,
  UNI,
  SOL,
  ATLAS,
  POLIS,
  USDC,
  USDBP,
  ABFY,
  ADA,
  LUNA,
  TERRA,
  LUNC,
  TERRAC,
  GMT,
  DOT,
  MATIC,
  LINK,
  XRP,
  BLOG_TRANSFER_SECURITY_POST,
  TIMEOUT_COPY_WALLET_ADDRESS_CHECK,
  INTERNAL,
  SUPPORT_ADDRESS,
  APP_STORE_ADDRESS,
  GOOGLE_PLAY_ADDRESS,
  PAXG,
  IS_RFQ_ACTIVE,
  CNPJ_BITYPRECO,
  FIREBASE_LINKS,
  FIREBASE_CURRENCIES_ISSUES,
  FIREBASE_TRANSLATIONS,
  CURRENCY_ICONS_URL,
  FIREBASE_FEATURE_FLAGS,
  FIREBASE_MGM_INFO,
};

export {
  dataRequestCacheController,
  INTERCOM_APP_ID,
  AVAILABLE_NETWORK,
  DEV,
  API_URL,
  SOCKET_URL,
  VARIANT,
  SUPPORT_MAIL,
  DEFAULT_SYNC_INTERVAL,
  LONG_SYNC_INTERVAL,
  SHORT_SYNC_INTERVAL,
  ORDERBOOK_SYNC_INTERVAL,
  GOOGLE_RECAPTCHA_SITE_KEY,
  GOOGLE_RECAPTCHA_SITE_KEY_V3,
  SHOW_INTERNAL_TRADEVIEW,
  ETH_BLOCK_EXPLORER,
  BSC_BLOCK_EXPLORER,
  getSolBlockExplorer,
  getEthBlockExplorer,
  getBtcBlockExplorer,
  getBscBlockExplorer,
  getAdaBlockExplorer,
  getLunaBlockExplorer,
  getLuncBlockExplorer,
  getTronBlockExplorer,
  BTC_BLOCK_EXPLORER,
  BTC_SATOSHI_SCALE,
  AVAILABLE_TOKENS,
  AVAILABLE_OPERATIONS,
  DATA_FEED_URL,
  CHARTS_STORAGE_URL,
  JIVO_WIDGET_ID,
  product,
  MS_TEN_MINUTES,
  MS_KEEP_ALIVE_INTERVAL_CHECK,
  USDT,
  ETH,
  BRL,
  BTC,
  BUSD,
  BSC,
  BNB,
  CRZO,
  AXS,
  SLP,
  CAKE,
  UNI,
  SOL,
  ATLAS,
  POLIS,
  USDC,
  USDBP,
  ABFY,
  ADA,
  LUNA,
  TERRA,
  LUNC,
  TERRAC,
  GMT,
  DOT,
  MATIC,
  LINK,
  XRP,
  BLOG_TRANSFER_SECURITY_POST,
  TIMEOUT_COPY_WALLET_ADDRESS_CHECK,
  INTERNAL,
  DEMO_ACCOUNT_EMAIL,
  DEMO_ACCOUNT_PASS,
  SUPPORT_ADDRESS,
  APP_STORE_ADDRESS,
  GOOGLE_PLAY_ADDRESS,
  VERSION_WEB,
  PAXG,
  FirebaseService,
  IS_RFQ_ACTIVE,
  CNPJ_BITYPRECO,
  DEPOSIT_BANK_INFO,
  TRANSLATION_ERRORS_URL,
  FIREBASE_LINKS,
  FIREBASE_CURRENCIES_ISSUES,
  FIREBASE_TRANSLATIONS,
  CURRENCY_ICONS_URL,
  FIREBASE_FEATURE_FLAGS,
  FIREBASE_MGM_INFO,
  triggerTradeEvent,
};

export default config;
